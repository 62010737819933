// dayjs
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';

// project constants
import { bookingStatuses, preferredTimeFrameOptions } from 'constants/bookings';

// project utils
import callAzureFunction from 'utils/call-azure-function';
import callAzureFunctionPublic from './call-azure-function-public';
import { areIntervalsOverlapping } from './date-helpers';
import { capitalizeFirstLetter } from './form-helpers';

import { RRule } from 'rrule';
import { managerLocationOptions } from 'constants/managerOptions';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(weekOfYear);

/**
 * Gets a `Dayjs` object with a set time depending on the provided parameter values.
 * @param {Number} hours Number of hours
 * @param {Number} min Number of minutes
 * @param {Number} sec Number of seconds
 * @param {Number} ms Number of milliseconds
 * @param {Date} date Date to use as a base, if not provided, current Date is used
 * @returns {Dayjs} Dayjs object
 */
const getDateWithCustomTime = (hours = 0, min = 0, sec = 0, ms = 0, date = null) => {
  const newDate = date ? dayjs(date) : dayjs();
  return newDate.hour(hours).minute(min).second(sec).millisecond(ms);
};

const validHours = (value) => Boolean(typeof value === 'number' && value >= 0 && value <= 23);

const validMinutes = (value) => Boolean(typeof value === 'number' && value >= 0 && value <= 59);

/**
 * Determines if the given day is valid work day or not.
 * @param {Date} date Date to be checked
 * @param {object} location Location object. If `location` is not provided, `locationWorkDays` will be used next
 * @param {string} locationWorkDays Comma separated list of work days e.g. `1,2,4,5`
 * @returns {boolean} `true` if day is valid, `false` otherwise
 */
const dayIsValidWorkday = (date, location = null, locationWorkDays = null, workDaysArray = null) => {
  if (!(date instanceof Date || date instanceof dayjs)) return false;

  const workDays =
    location?.workDays?.split(',')?.map((day) => Number(day)) ||
    locationWorkDays?.split(',')?.map((day) => Number(day)) ||
    workDaysArray ||
    [];
  return Boolean(workDays.includes(dayjs(date).day()));
};

/**
 * Determines if the given day is unavailalbe or not.
 * @param {Date} date Date to be checked
 * @param {object} location Location object. If `location` is not provided, `locationUnavailableDays` will be used next
 * @param {object[]} locationUnavailableDays Array of unavailable days
 * @returns {boolean} `true` if day is unavailalbe, `false` otherwise
 */
const dayIsUnavailable = (date, location = null, locationUnavailableDays = []) => {
  if (!(date instanceof Date || date instanceof dayjs)) return false;

  const unavailableDays = location?.UnavailableDays || locationUnavailableDays || [];

  // recurring & non recurring dates
  const udRecurring = unavailableDays?.filter((ud) => ud.recurring) || [];
  const udNonRecurring = unavailableDays?.filter((ud) => !ud.recurring) || [];

  const unavailableRecurring = udRecurring.find((ud) => {
    const udDate = dayjs(ud.date);
    // check if day of month and month are the same
    return dayjs(date).get('date') === dayjs(udDate).get('date') && dayjs(date).get('month') === dayjs(udDate).get('month');
  });
  const unavailableNonRecurring = udNonRecurring.find((ud) => {
    const udDateISO = dayjs(ud.date).format('YYYY-MM-DD');
    // check if both ISO dates are the same
    return udDateISO === dayjs(date).format('YYYY-MM-DD');
  });

  return Boolean(unavailableRecurring || unavailableNonRecurring);
};

const dateIsValidAtLocation = (date, timeSlot, location) => {
  if (!(date instanceof Date || date instanceof dayjs) || !timeSlot || !location) {
    return false;
  }

  try {
    const dateAtLocationNow = location?.timezone ? dayjs().tz(location.timezone) : dayjs();
    const assembledDate = `${dayjs(date).format('YYYY-MM-DD')}T${dayjs(timeSlot.start).format('HH:mm')}`;
    const bookingDate = dayjs(assembledDate);

    return bookingDate.isAfter(dateAtLocationNow);
  } catch (error) {
    return false;
  }
};

/**
 * Validates if a given date is valid based on the provided recurrence rule.
 *
 * @param {Object} paramObject - An object containing parameters:
 *   @param {dayjs} dayjsdate - The date to be validated, which is in DayJS data type.
 *   @param {dayjs} cycleStartDate - The start date of the cycle.
 *   @param {Array} workdays - An array of weekdays for the rule.
 *   @param {number} weeklyInterval - The interval for weekly recurrence.
 *   @param {RRule} rruleObj - The RRule object for recurrence.
 *   @param {string} rruleStr - The RRule string representation.
 * @returns {boolean} Returns true if the date is valid according to the rule, false otherwise.
 */
const dateIsValidPerRRULE = ({ dayjsdate, cycleStartDate, workdays, weeklyInterval, rruleObj, rruleStr }) => {
  if (!(dayjsdate instanceof dayjs)) return false;

  let isValid = true;

  let rule;

  if (rruleObj && rruleObj instanceof RRule) {
    rule = rruleObj;
  } else if (rruleStr && typeof rruleStr === 'string') {
    rule = RRule.fromString(rruleStr);
  } else if (weeklyInterval && cycleStartDate && cycleStartDate instanceof dayjs && workdays.length) {
    rule = new RRule({
      freq: RRule.WEEKLY,
      interval: weeklyInterval,
      dtstart: cycleStartDate.toDate(),
      byweekday: workdays
    });
  } else {
    return isValid;
  }

  const startDate = dayjsdate.subtract(1, 'day').toDate();
  const thirtyDaysFromStartDate = dayjsdate.add(30, 'days').toDate();

  // https://github.com/jkbrzt/rrule?tab=readme-ov-file#usage
  // get all occurrence dates within a specific period based on the rrule

  const recurrenceSet = rule.between(startDate, thirtyDaysFromStartDate);

  isValid = recurrenceSet.some((date) => dayjs(date).isSame(dayjsdate, 'date'));

  return isValid;
};

const dateIsValidPerRRulesOfAllGZLocations = (dayjsdate, groundZeroLocations) => {
  let isValid = true;

  if (!groundZeroLocations.length) {
    return isValid;
  }

  const allRRules = groundZeroLocations.map((gzLoc) => gzLoc.rrule).filter((rrule) => rrule !== null);

  const validityCheckResultArray = [];

  for (const rrule of allRRules) {
    const rruleObj = RRule.fromString(rrule);
    const isDateValid = dateIsValidPerRRULE({ dayjsdate, rruleObj });

    validityCheckResultArray.push(isDateValid);
  }

  isValid = validityCheckResultArray.includes(true);

  return isValid;
};

const dateIsValidAsPerTimezone = (date, timeSlot, timezone) => {
  if (!(date instanceof Date || date instanceof dayjs) || !timeSlot) {
    return false;
  }

  try {
    const dateAtLocationNow = timezone ? dayjs().tz(timezone) : dayjs();
    const assembledDate = `${dayjs(date).format('YYYY-MM-DD')}T${dayjs(timeSlot.start).format('HH:mm')}`;
    const bookingDate = dayjs(assembledDate);

    return bookingDate.isAfter(dateAtLocationNow);
  } catch (error) {
    return false;
  }
};

const getSelectedGZLocationPerRRule = (dateValue, gzLocations) => {
  const selectedGZLocation = gzLocations.find((gzLoc) => dateIsValidPerRRULE({ dayjsdate: dayjs(dateValue), rruleStr: gzLoc.rrule }));

  return selectedGZLocation;
};

const getAllWorkDays = (arrayOfObjWithWorkDays) => {
  let allWorkDays = [];

  if (!arrayOfObjWithWorkDays || !arrayOfObjWithWorkDays.length) {
    return allWorkDays;
  }

  allWorkDays = arrayOfObjWithWorkDays
    .map((ea) => ea.workDays.split(',').map((d) => Number(d)) || [])
    .flat()
    .reduce((a, day) => {
      if (!a.includes(day)) {
        a.push(day);
      }
      return a;
    }, [])
    // sort
    .sort((a, b) => a - b);

  return allWorkDays;
};

const getEarliestAvailableDate = (location) => {
  let currentDate = dayjs();

  if (!location) {
    return currentDate;
  }

  for (let index = 0; ; index += 1) {
    if (dayIsValidWorkday(currentDate, location) && !dayIsUnavailable(currentDate, location)) {
      break;
    }
    currentDate = currentDate.add(1, 'day');
  }

  return currentDate;
};

/**
 * Gets the calendar events of a user in Outlook calendar
 * https://learn.microsoft.com/en-us/graph/api/user-list-calendarview?view=graph-rest-1.0&tabs=http#http-request
 * @param {object} options - The options for getting the calendar events.
 * @param {string} options.userPrincipalName - The user principal name to fetch events for.
 * @param {string} [options.managerId] - The manager id to fetch events for. Only used if publicMode is true.
 * @param {Date} options.bookingDate - The start of the time range to fetch events for, in ISO8601 format.
 * @param {string} [options.timezone] - The timezone to fetch events for, in IANA format. Optional, typically passed when manager is in a specific location and null if the manager is MSP
 * @param {boolean} [options.publicMode] - Whether to call the public or private Azure function.
 * @returns {Promise<Array<object>>} An array of calendar events.
 */
const getOutlookCalendarEventsOfUser = async ({ userPrincipalName, managerId = null, bookingDate, timezone, publicMode }) => {
  const requestForOutlookCalendarEvents = {
    url: publicMode ? `public/outlook-calendar/events` : `outlook-calendar/events`,
    method: 'get',
    params: {
      userPrincipalName,
      managerId: publicMode ? managerId : null,
      timeMin: dayjs(bookingDate).utc().startOf('day').toISOString(),
      timeMax: dayjs(bookingDate).utc().endOf('day').toISOString(),
      timezone
    }
  };
  const response = publicMode
    ? await callAzureFunctionPublic(requestForOutlookCalendarEvents)
    : await callAzureFunction(requestForOutlookCalendarEvents);

  const events = response?.data;

  return events || [];
};

/**
 * Gets the calendar events of a user in Google calendar
 * https://developers.google.com/calendar/api/v3/reference/events/list
 * @param {object} options - The options for getting the calendar events.
 * @param {string} options.userEmailAddress - The user principal name to fetch events for.
 * @param {string} [options.managerId] - The manager id to fetch events for. Only used if publicMode is true.
 * @param {Date} options.bookingDate - The start of the time range to fetch events for, in ISO8601 format.
 * @param {object} [options.location] - The location object for which to fetch events. The location object is used to filter the events by the work hours of the location.
 * @param {boolean} [options.publicMode] - Whether to call the public or private Azure function.
 * @returns {Promise<Array<object>>} An array of calendar events.
 */
const getGoogleCalendarEventsOfUser = async ({ userEmailAddress, managerId, bookingDate, location, publicMode }) => {
  const { workStartHours, workStartMinutes, workEndHours, workEndMinutes, timezone } = location || {};

  const requestForGoogleCalendarEvents = {
    url: publicMode ? `public/google-calendar/events` : `google-calendar/events`,
    method: 'get',
    params: {
      subject: userEmailAddress,
      managerId: publicMode ? managerId : null,
      timeMin: dayjs
        .tz(
          `${dayjs(bookingDate).format('YYYY-MM-DD')}T${String(workStartHours || 8).padStart(2, '0')}:${String(workStartMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeMax: dayjs
        .tz(
          `${dayjs(bookingDate).format('YYYY-MM-DD')}T${String(workEndHours || 17).padStart(2, '0')}:${String(workEndMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeZone: timezone
    }
  };

  const responseForGoogleCalendarEvents = publicMode
    ? await callAzureFunctionPublic(requestForGoogleCalendarEvents)
    : await callAzureFunction(requestForGoogleCalendarEvents);

  const events = responseForGoogleCalendarEvents.data;

  return events || [];
};

/**
 * Gets the available time slots for a service at a location on a given date.
 * The time slots are calculated by taking into account the work hours, lunch break, and existing bookings at the location.
 * The time slots are also filtered to make sure they do not conflict with the manager's calendar events if the manager is in a specific location.
 * If the manager is an MSP, the time slots are not filtered by the manager's calendar events.
 * @param {object} options - The options for getting the available time slots.
 * @param {Date} options.date - The date to get the available time slots for.
 * @param {object} options.service - The service object for which to get the available time slots.
 * @param {object} options.location - The location object for which to get the available time slots.
 * @param {object} options.manager - The manager object for which to get the available time slots. The manager object is used to filter the time slots by the manager's calendar events.
 * @param {boolean} options.managerHasOutlookCalendar - Whether the manager has an Outlook calendar.
 * @param {boolean} options.managerHasGoogleCalendar - Whether the manager has a Google calendar.
 * @param {boolean} options.publicMode - Whether to call the public or private Azure function.
 * @param {number} options.managerOption - The selected manager location option. Allows calendar event conflict checks if the value is specific manager at location.
 * @returns {Promise<Array<object>>} An array of available time slots.
 */
const getAvailableTimeSlots = async ({
  date,
  service,
  location,
  manager,
  managerHasOutlookCalendar = false,
  managerHasGoogleCalendar = false,
  publicMode = false,
  managerOption
}) => {
  const timeSlots = [];

  if (!service || !location) {
    return timeSlots;
  }

  const {
    workStartHours,
    workStartMinutes,
    workEndHours,
    workEndMinutes,
    lunchStartHours,
    lunchStartMinutes,
    lunchEndHours,
    lunchEndMinutes
  } = location;

  let workStartTime = null;
  let workEndTime = null;
  let lunchStartTime = null;
  let lunchEndTime = null;

  if (validHours(workStartHours) && validMinutes(workStartMinutes) && validHours(workEndHours) && validMinutes(workEndMinutes)) {
    workStartTime = getDateWithCustomTime(workStartHours, workStartMinutes, 0, 0, date);
    workEndTime = getDateWithCustomTime(workEndHours, workEndMinutes, 0, 0, date);
  } else {
    return timeSlots;
  }

  if (validHours(lunchStartHours) && validMinutes(lunchStartMinutes) && validHours(lunchEndHours) && validMinutes(lunchEndMinutes)) {
    lunchStartTime = getDateWithCustomTime(lunchStartHours, lunchStartMinutes, 0, 0, date);
    lunchEndTime = getDateWithCustomTime(lunchEndHours, lunchEndMinutes, 0, 0, date);
  }

  const serviceDuration = service.duration;

  if (!serviceDuration) {
    return timeSlots;
  }

  // start and end time for time slot
  let start = workStartTime;
  let end = dayjs(start).add(serviceDuration, 'minute');

  const request = {
    url: publicMode ? `/public/bookings/location/${location.id}` : `bookings/location/${location.id}`,
    method: 'get',
    params: {
      date: dayjs(date).format('YYYY-MM-DD'),
      excludeStatuses: [bookingStatuses.Canceled.value, bookingStatuses.DidNotAttend.value].join(',')
    }
  };

  // get all bookings for this date at this location
  const response = publicMode ? await callAzureFunctionPublic(request) : await callAzureFunction(request);

  const unavailableBookingSlots = response.data.map((booking) => {
    const { startHours, startMinutes, endHours, endMinutes } = booking;
    return {
      start: getDateWithCustomTime(startHours, startMinutes, 0, 0, date),
      end: getDateWithCustomTime(endHours, endMinutes, 0, 0, date)
    };
  });

  const outlookCalendarEvents =
    managerHasOutlookCalendar && manager && managerOption === managerLocationOptions.specific.value
      ? await getOutlookCalendarEventsOfUser({
          userPrincipalName: manager.emailAddress,
          managerId: manager.id,
          bookingDate: date,
          timezone: location.timezone,
          publicMode
        })
      : [];

  const googleCalendarEvents =
    managerHasGoogleCalendar && manager && managerOption === managerLocationOptions.specific.value
      ? await getGoogleCalendarEventsOfUser({
          userEmailAddress: manager.emailAddress,
          managerId: manager.id,
          bookingDate: date,
          location,
          publicMode
        })
      : [];

  while (start >= workStartTime && start < workEndTime && end > workStartTime && end <= workEndTime) {
    // check for conflicts with lunch time
    const lunchConflict =
      lunchStartTime && lunchEndTime && areIntervalsOverlapping({ start: lunchStartTime, end: lunchEndTime }, { start, end });
    // check for conflicts with at least one exisiting booking
    const bookingConflict = unavailableBookingSlots.find(
      // eslint-disable-next-line no-loop-func
      (slot) => areIntervalsOverlapping({ start: slot.start, end: slot.end }, { start, end })
    );

    // eslint-disable-next-line no-loop-func
    const outlookCalendarConflict = outlookCalendarEvents?.find((event) => {
      const { startDate, startHours, startMinutes, endDate, endHours, endMinutes } = event;
      const eventStart = dayjs(startDate).hour(startHours).minute(startMinutes).toDate();
      const eventEnd = dayjs(endDate).hour(endHours).minute(endMinutes).toDate();
      return areIntervalsOverlapping({ start: eventStart, end: eventEnd }, { start, end }, { inclusive: true });
    });

    // eslint-disable-next-line no-loop-func
    const googleCalendarConflict = googleCalendarEvents?.find((event) => {
      const { startDate, startHours, startMinutes, endDate, endHours, endMinutes } = event;
      const eventStart = getDateWithCustomTime(startHours, startMinutes, 0, 0, startDate);
      const eventEnd = getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
      return areIntervalsOverlapping({ start: eventStart, end: eventEnd }, { start, end });
    });

    const timeSlot = {
      start,
      startText: dayjs(start).format('hh:mm a'),
      end,
      endText: dayjs(end).format('hh:mm a')
    };

    if (
      !lunchConflict &&
      !bookingConflict &&
      !outlookCalendarConflict &&
      !googleCalendarConflict &&
      dateIsValidAtLocation(date, timeSlot, location)
    ) {
      timeSlots.push(timeSlot);
    }

    start = lunchConflict ? lunchEndTime : end;
    end = dayjs(start).add(serviceDuration, 'minute');
  }

  return timeSlots;
};

const getWorkdaysTextPerRRule = (rruleStr) => {
  let workdaysText = '';

  if (rruleStr && typeof rruleStr === 'string') {
    const rrule = RRule.fromString(rruleStr);

    try {
      const parsedRRule = rrule.toText();
      workdaysText = capitalizeFirstLetter(parsedRRule);
    } catch (error) {
      workdaysText = 'N/A';
    }
  }

  return workdaysText;
};

const hasNoLunchTime = (locationObj) => {
  if (!locationObj || typeof locationObj !== 'object') {
    return true;
  }

  return (
    locationObj.lunchStartHours === null ||
    locationObj.lunchStartMinutes === null ||
    locationObj.lunchEndHours === null ||
    locationObj.lunchEndMinutes === null
  );
};

const getMobileServiceProviderTimeSlots = async ({
  date,
  service,
  selectedGroundZeroLocation,
  publicMode = false,
  ignoreGroundZeroLocation = false,
  timezonePerMobileBookingPlaceId
}) => {
  const timeSlots = [];

  if (!service || (!ignoreGroundZeroLocation && !selectedGroundZeroLocation) || !date) {
    return timeSlots;
  }

  const {
    workStartHours,
    workStartMinutes,
    workEndHours,
    workEndMinutes,
    lunchStartHours,
    lunchStartMinutes,
    lunchEndHours,
    lunchEndMinutes,
    timezone: groundZeroLocationTimezone
  } = selectedGroundZeroLocation;

  let timezone;

  let workStartTime = null;
  let workEndTime = null;
  let lunchStartTime = null;
  let lunchEndTime = null;

  if (ignoreGroundZeroLocation) {
    // ground zero location is only ignored in case of unconfirmed booking
    // in such case, the timezone as per mobile booking place id shall be used
    // the default work start time shall be 8:00 AM and ends at 5:00 PM
    // no lunch time is taken into account

    timezone = timezonePerMobileBookingPlaceId;
    workStartTime = getDateWithCustomTime(8, 0, 0, 0, date);
    workEndTime = getDateWithCustomTime(17, 0, 0, 0, date);
  } else {
    timezone = groundZeroLocationTimezone;

    if (validHours(workStartHours) && validMinutes(workStartMinutes) && validHours(workEndHours) && validMinutes(workEndMinutes)) {
      workStartTime = getDateWithCustomTime(workStartHours, workStartMinutes, 0, 0, date);
      workEndTime = getDateWithCustomTime(workEndHours, workEndMinutes, 0, 0, date);
    } else {
      return timeSlots;
    }

    if (validHours(lunchStartHours) && validMinutes(lunchStartMinutes) && validHours(lunchEndHours) && validMinutes(lunchEndMinutes)) {
      lunchStartTime = getDateWithCustomTime(lunchStartHours, lunchStartMinutes, 0, 0, date);
      lunchEndTime = getDateWithCustomTime(lunchEndHours, lunchEndMinutes, 0, 0, date);
    }
  }

  const serviceDuration = service.duration;

  if (!serviceDuration) {
    return timeSlots;
  }

  // start and end time for time slot
  let start = workStartTime;
  let end = dayjs(start).add(serviceDuration, 'minute');

  const requestForBookings = {
    url: publicMode
      ? `public/bookings/manager/${selectedGroundZeroLocation?.managerId}`
      : `bookings/manager/${selectedGroundZeroLocation?.managerId}`,
    method: 'get',
    params: {
      date: dayjs(date).format('YYYY-MM-DD'),
      excludeStatuses: [bookingStatuses.Canceled.value, bookingStatuses.DidNotAttend.value].join(',')
    }
  };

  // get all bookings for this date at this location
  const responseForBookings = publicMode ? await callAzureFunctionPublic(requestForBookings) : await callAzureFunction(requestForBookings);

  const requestForGoogleCalendarEvents = {
    url: publicMode ? `public/google-calendar/events` : `google-calendar/events`,
    method: 'get',
    params: {
      subject: selectedGroundZeroLocation?.emailAddress,
      timeMin: dayjs
        .tz(
          `${dayjs(date).format('YYYY-MM-DD')}T${String(ignoreGroundZeroLocation ? 8 : workStartHours).padStart(2, '0')}:${String(workStartMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeMax: dayjs
        .tz(
          `${dayjs(date).format('YYYY-MM-DD')}T${String(ignoreGroundZeroLocation ? 17 : workEndHours).padStart(2, '0')}:${String(workEndMinutes || 0).padStart(2, '0')}:00`,
          timezone
        )
        .toISOString(),
      timeZone: timezone
    }
  };

  const responseForGoogleCalendarEvents = publicMode
    ? await callAzureFunctionPublic(requestForGoogleCalendarEvents)
    : await callAzureFunction(requestForGoogleCalendarEvents);

  const mspEmailAddress = selectedGroundZeroLocation?.emailAddress;

  const bookings = responseForBookings.data;
  const googleCalendarEvents = responseForGoogleCalendarEvents.data;
  const outlookCalendarEvents = await getOutlookCalendarEventsOfUser({
    userPrincipalName: mspEmailAddress,
    bookingDate: date,
    managerId: selectedGroundZeroLocation?.managerId,
    timezone,
    publicMode
  });

  const unavailableBookingSlots = bookings?.map((booking) => {
    const { startHours, startMinutes, endHours, endMinutes, isMobileBooking, travelTimeBufferMinutes } = booking;

    let unavailableSlot = {
      start: getDateWithCustomTime(startHours, startMinutes, 0, 0, date),
      end: getDateWithCustomTime(endHours, endMinutes, 0, 0, date)
    };

    if (isMobileBooking) {
      const startDateObj = dayjs(getDateWithCustomTime(startHours, startMinutes, 0, 0, date)).subtract(
        Number(travelTimeBufferMinutes || 0),
        'minute'
      );

      const endDateObj = dayjs(getDateWithCustomTime(endHours, endMinutes, 0, 0, date)).add(Number(travelTimeBufferMinutes || 0), 'minute');

      unavailableSlot = {
        start: startDateObj,
        end: endDateObj
      };
    }

    return unavailableSlot;
  });

  while (start >= workStartTime && start < workEndTime && end > workStartTime && end <= workEndTime) {
    // check for conflicts with lunch time
    const lunchConflict =
      lunchStartTime && lunchEndTime && areIntervalsOverlapping({ start: lunchStartTime, end: lunchEndTime }, { start, end });
    // check for conflicts with at least one exisiting booking
    const bookingConflict = unavailableBookingSlots.find(
      // eslint-disable-next-line no-loop-func
      (slot) => areIntervalsOverlapping({ start: slot.start, end: slot.end }, { start, end })
    );
    // check for conflicts with google calendar events
    // eslint-disable-next-line no-loop-func
    const googleCalendarConflict = googleCalendarEvents?.find((event) => {
      const { startDate, startHours, startMinutes, endDate, endHours, endMinutes } = event;
      const eventStart = getDateWithCustomTime(startHours, startMinutes, 0, 0, startDate);
      const eventEnd = getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
      return areIntervalsOverlapping({ start: eventStart, end: eventEnd }, { start, end });
    });

    // eslint-disable-next-line no-loop-func
    const outlookCalendarConflict = outlookCalendarEvents?.find((event) => {
      const { startDate, startHours, startMinutes, endDate, endHours, endMinutes } = event;
      const eventStart = getDateWithCustomTime(startHours, startMinutes, 0, 0, startDate);
      const eventEnd = getDateWithCustomTime(endHours, endMinutes, 0, 0, endDate);
      return areIntervalsOverlapping({ start: eventStart, end: eventEnd }, { start, end }, { inclusive: true });
    });

    const timeSlot = {
      start,
      startText: dayjs(start).format('hh:mm a'),
      end,
      endText: dayjs(end).format('hh:mm a')
    };

    if (
      !lunchConflict &&
      !bookingConflict &&
      !googleCalendarConflict &&
      !outlookCalendarConflict &&
      (ignoreGroundZeroLocation
        ? dateIsValidAsPerTimezone(date, timeSlot, timezone)
        : dateIsValidAtLocation(date, timeSlot, selectedGroundZeroLocation))
    ) {
      timeSlots.push(timeSlot);
    }

    start = lunchConflict ? lunchEndTime : end;
    end = dayjs(start).add(serviceDuration, 'minute');
  }

  return timeSlots;
};

/**
 * Gets a booking creation origin, i.e. where a booking was created from.
 * @param {*} booking Booking object
 * @returns creation origin string
 */
const getCreatedFrom = (booking = {}) => {
  const { adminCreated, managerCreated, bookingPageId, respondentAvailabilityIsKnown, isMobileBooking } = booking;
  const createdByAdminOrManager = Boolean(adminCreated || managerCreated);
  const fromBookingPage = Boolean(bookingPageId);

  // booking created from booking page
  if (createdByAdminOrManager && fromBookingPage) {
    return 'Booking Page';
  }

  // booking created from public booking page
  if (!createdByAdminOrManager && fromBookingPage && isMobileBooking && !respondentAvailabilityIsKnown) {
    return 'Public Booking Page - Get Help From Admin';
  }

  // booking created from public booking page
  if (!createdByAdminOrManager && fromBookingPage) {
    return 'Public Booking Page';
  }
  // booking created from calendar
  if (createdByAdminOrManager && !fromBookingPage) {
    return 'Calendar';
  }

  return '';
};

const getPreferredTimeFrameLabel = (preferredTimeFrame) => {
  if (!preferredTimeFrame) {
    return '-';
  }

  const preferredTimeFrameOption = preferredTimeFrameOptions.find((option) => option.value === preferredTimeFrame);

  return preferredTimeFrameOption ? preferredTimeFrameOption.label : '-';
};

const defaultWorkTimeSchedules = {
  workStartTime: getDateWithCustomTime(8, 0, 0, 0),
  workEndTime: getDateWithCustomTime(17, 0, 0, 0),
  lunchStartTime: getDateWithCustomTime(12, 0, 0, 0),
  lunchEndTime: getDateWithCustomTime(13, 0, 0, 0)
};

export {
  getDateWithCustomTime,
  validHours,
  validMinutes,
  dayIsValidWorkday,
  dayIsUnavailable,
  dateIsValidAtLocation,
  getEarliestAvailableDate,
  getAvailableTimeSlots,
  getCreatedFrom,
  getMobileServiceProviderTimeSlots,
  getAllWorkDays,
  getSelectedGZLocationPerRRule,
  getWorkdaysTextPerRRule,
  dateIsValidPerRRULE,
  dateIsValidPerRRulesOfAllGZLocations,
  defaultWorkTimeSchedules,
  hasNoLunchTime,
  getPreferredTimeFrameLabel
};
