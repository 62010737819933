// react
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  Grid
} from '@mui/material';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// tabler icons
import { IconLogout, IconUserCircle } from '@tabler/icons-react';

// jwt
// import { jwtDecode } from 'jwt-decode';

// notistack
// import { enqueueSnackbar } from 'notistack';

// project constants
// import userTypes from 'constants/userTypes';

// project utils
import getAppVersion from 'utils/app-version';
import { handleLogout } from 'utils/logout';
// import {
//   checkIfEmailBelongsToLoggedInUser,
//   acquireTokenSilentlyAndSyncCalendarData,
//   syncOutlookCalendarEventsToDB
// } from 'utils/msGraphQueryHelper';

// project components
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
// import User1 from 'assets/images/users/user-round.svg';
import CopyToClipboardWithMUI from 'views/manager/absence-management/CopyToClipBoard';
import userTypes from 'constants/userTypes';
import { useGetEntityName } from 'utils/entities';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const userData = useSelector((state) => state.user?.data);
  const navigate = useNavigate();

  const userAccessRole = userData.AccessRoles?.length ? userData.AccessRoles[0].name : 'N/A';
  const userTypeEntityName = useGetEntityName(userData.type === userTypes.ExternalUser ? 'externalUser' : userData?.type);

  // const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const { appVersion, commitHashShort, commitHashComplete } = getAppVersion();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Box ref={anchorRef} style={{ cursor: 'pointer' }} onClick={handleToggle}>
        {userData?.profilePhoto ? (
          <img src={userData?.profilePhoto} style={{ width: 40, height: 40, objectFit: 'cover', borderRadius: '50%' }} alt="avatar" />
        ) : (
          <IconUserCircle color="#1ad19e" stroke={1} size={45} />
        )}
      </Box>

      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Day,</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {userData?.firstName && userData?.lastName ? `${userData.firstName} ${userData.lastName}` : ''}
                        </Typography>
                      </Stack>
                      <Typography my={0.5} variant="subtitle2" sx={{ textTransform: 'capitalize' }}>
                        {userTypeEntityName || ''} {userData?.type === userTypes.ExternalUser && `| ${userAccessRole}`}
                      </Typography>
                      {appVersion && commitHashShort && (
                        <Stack direction="row" alignItems="center" mt={1}>
                          <Typography variant="subtitle2">
                            v{appVersion} | {commitHashShort}
                          </Typography>
                          {commitHashComplete && (
                            <CopyToClipboardWithMUI
                              textToCopy={commitHashComplete}
                              iconButtonSx={{ fontSize: '12px' }}
                              showSnackbar={false}
                            />
                          )}
                        </Stack>
                      )}
                    </Stack>
                  </Box>
                  <Grid style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}>
                    <Box sx={{ p: 1 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >
                        {/* -- put outlook calendar feature to sleep
                          {userData.type === userTypes.Manager && (
                          <ListItemButton
                            disabled={isLoading}
                            sx={{ borderRadius: `${customization.borderRadius}px` }}
                            onClick={handleAcquireCalendarToken}
                          >
                            <ListItemIcon>
                              <CalendarMonthIcon stroke={1.2} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Sync Outlook Calendar Events</Typography>} />
                          </ListItemButton>
                        )} */}
                        <ListItemButton
                          sx={{ borderRadius: `${customization.borderRadius}px` }}
                          onClick={async () => {
                            await handleLogout();
                            navigate('/login');
                          }}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </Grid>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
